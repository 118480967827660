"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.openLoginModal = exports.closeLoginModal = void 0;
const types_1 = require("../types");
const loading_js_1 = require("../assets/loading.js");
const DEFAULT_HEIGHT = 600;
const MIN_HEIGHT = 520;
const modal = {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 19999,
    backgroundColor: "rgba(57, 57, 57, 0.15)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
const contentWrapCss = (isHasAdvertisement, isLimitHeight) => ({
    width: "400px",
    maxHeight: isLimitHeight ? "calc(100vh - 20px)" : 'unset',
    // position: "relative",
    boxSizing: "border-box",
    zIndex: 20000,
    borderRadius: isHasAdvertisement ? "unset" : "20px",
    // boxShadow: "0 2px 10px 2px rgba(0, 0, 0, 0.1)",
    overflow: "hidden auto",
    backgroundColor: "white",
});
const content = (passportHeight, isFullWin) => ({
    height: isFullWin ? "100%" : `${(passportHeight || DEFAULT_HEIGHT)}px`,
    position: "relative",
});
const advertisementCss = (isFullWin) => ({
    display: "flex",
    borderRadius: "20px",
    overflow: isFullWin ? 'unset' : "hidden",
    alignItems: 'center',
    position: "relative",
});
const advertisementContentDomCss = (isRight, passportHeight, isLimitHeight) => ({
    height: `${(passportHeight || DEFAULT_HEIGHT)}px`,
    backgroundColor: "white",
    order: isRight ? 1 : 0,
    maxHeight: isLimitHeight ? "calc(100vh - 20px)" : 'unset',
});
const fullWinContent = {
    width: "100vw",
    height: "100vh",
    borderRadius: "0",
};
const closeBtn = {
    position: "absolute",
    top: "0.3rem",
    right: "0.4rem",
    zIndex: "100",
    cursor: "pointer",
    fontSize: "20px",
    width: "24px",
    height: "24px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
};
const iframe = (passportHeight, isFullWin) => ({
    boxSizing: "border-box",
    width: "100%",
    height: isFullWin ? "100%" : `${passportHeight || DEFAULT_HEIGHT}px`,
});
const loadingCss = (loading) => ({
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%,-50%)',
    display: loading ? 'block' : 'none'
});
const domIds = {
    modal: "passport-modal-container",
    advertisement: "passport-advertisement-container",
    advertisementContent: "passport-advertisement-content",
    contentWrap: "passport-modal-content-wrap",
    contentScroll: "passport-modal-content-scroll",
    content: "passport-modal-content",
    closeBtn: "passport-modal-close-btn",
    iframe: "passport-modal-iframe",
    loading: "passport-modal-loading",
};
const closeLoginModal = (reason) => {
    const modalDom = document.querySelector(`#${domIds.modal}`);
    if (modalDom && modalDom.remove) {
        modalDom.remove();
        window.postMessage({ action: "close-passport-modal", reason });
        window.removeEventListener("message", winChangeHandle);
    }
};
exports.closeLoginModal = closeLoginModal;
const winChangeHandle = (e) => {
    const data = e.data;
    const { action, payload } = data;
    if (action === "win-height-change") {
        const contentDom = document.querySelector(`#${domIds.content}`);
        const loadingDom = document.querySelector(`#${domIds.loading}`);
        const iframeDom = document.querySelector(`#${domIds.iframe}`);
        const advertisementContentDom = document.querySelector(`#${domIds.advertisementContent}`);
        if (contentDom) {
            const height = payload > MIN_HEIGHT ? payload : MIN_HEIGHT;
            contentDom.style.height = `${height}px`;
            advertisementContentDom.style.height = `${height}px`;
            iframeDom.style.height = `${height}px`;
        }
        if (loadingDom) {
            iframeDom.style.visibility = 'visible';
            loadingDom.remove();
        }
    }
};
// 隐藏关闭按钮
const hideModalCloseHandle = (e) => {
    const data = e.data;
    const { action, from } = data;
    if (action === "hide-modal-close" &&
        ["xtool-passport-web"].some((i) => i === from)) {
        const closeDom = document.querySelector(`#${domIds.closeBtn}`);
        if (closeDom) {
            closeDom.style.display = "none";
        }
    }
};
const openLoginModal = (loginUrl, mode, options) => {
    const page = loginUrl;
    const { advertisement, advertisementPosition, height: passportHeight, loading, adaptWindow } = options;
    const isFullWin = mode === types_1.PAGE_MODE.FULL_WIN;
    const isWin = mode === types_1.PAGE_MODE.WIN;
    const isLimitHeight = (adaptWindow && !isFullWin);
    let prefetchIframe = document.querySelector(`#passport-prefetch-iframe`);
    if (prefetchIframe)
        prefetchIframe.remove();
    if (isWin) {
        window.removeEventListener("message", winChangeHandle);
        if (!passportHeight) {
            window.addEventListener("message", winChangeHandle);
        }
        window.removeEventListener("message", hideModalCloseHandle);
        window.addEventListener("message", hideModalCloseHandle);
    }
    // 检查弹框是否已经存在
    const modalDom = document.querySelector(`#${domIds.modal}`);
    if (modalDom) {
        return;
    }
    const style = `
    <style>
    @media (max-width: 576px) {
        #passport-modal-content-wrap {
          position: fixed !important;
          left:0 !important;
          top:0 !important;
          width: 100% !important;
          height: 100% !important;
          max-height: 100% !important;
          border-radius: 0px !important;
        }
      }
    </style>
  `;
    // 往 body 写入 dom
    const bodyDom = document.querySelector("body");
    const html = `<div id="${domIds.modal}" >
                ${style}
            <div id="${domIds.advertisement}">
              <div id="${domIds.advertisementContent}"></div>
              <div id="${domIds.contentWrap}"> 
                <span id="${domIds.closeBtn}">×</span>
                <div id="${domIds.contentScroll}" style="height:100%;overflow:${'hidden'};">
                    <div id="${domIds.content}" >
                    <iframe id="${domIds.iframe}" src="${page}" scrolling="no" frameborder="0"></iframe>
                    ${loading ? `<img id="${domIds.loading}" src="data:image/gif;base64,${loading_js_1.loadingUrl}" style="width: 15%;" />` : ''}   
                    </div>
                </div>
              </div>
            </div>
        </div>`;
    const el = document.createElement("div");
    el.innerHTML += html;
    bodyDom === null || bodyDom === void 0 ? void 0 : bodyDom.append(el);
    // 获取 Dom 元素，用于后续插入 css
    const loginModalDom = document.querySelector(`#${domIds.modal}`);
    const advertisementDom = document.querySelector(`#${domIds.advertisement}`);
    const advertisementContentDom = document.querySelector(`#${domIds.advertisementContent}`);
    const contentWrapDom = document.querySelector(`#${domIds.contentWrap}`);
    const contentDom = document.querySelector(`#${domIds.content}`);
    const iframeDom = document.querySelector(`#${domIds.iframe}`);
    const closeDom = document.querySelector(`#${domIds.closeBtn}`);
    const loadingDom = document.querySelector(`#${domIds.loading}`);
    if (loadingDom) {
        iframeDom.style.visibility = 'hidden';
        loadingDom.style.visibility = 'visible';
        iframeDom.onload = function () {
            iframeDom.style.visibility = 'visible';
            loadingDom.remove();
        };
    }
    if (advertisement) {
        advertisement.style.height = '100%';
        advertisementContentDom === null || advertisementContentDom === void 0 ? void 0 : advertisementContentDom.appendChild(advertisement);
    }
    const clickCloseLoginModal = () => {
        (0, exports.closeLoginModal)("click-close-btn");
    };
    closeDom.onclick = clickCloseLoginModal;
    const parseCss = (el, obj) => {
        if (el) {
            for (const key in obj) {
                el.style[key] = obj[key];
            }
        }
    };
    parseCss(loginModalDom, modal);
    parseCss(advertisementDom, advertisementCss(isFullWin));
    parseCss(advertisementContentDom, advertisementContentDomCss(advertisementPosition === 'right', passportHeight, isLimitHeight));
    parseCss(contentWrapDom, Object.assign({}, contentWrapCss(!!advertisement, isLimitHeight), isFullWin ? fullWinContent : {}));
    parseCss(contentDom, content(passportHeight, isFullWin));
    parseCss(closeDom, closeBtn);
    parseCss(iframeDom, iframe(passportHeight, isFullWin));
    parseCss(loadingDom, loadingCss(loading));
};
exports.openLoginModal = openLoginModal;
