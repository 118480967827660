"use strict";
// 跨站方案：https://makeblock.feishu.cn/wiki/ZUSdwGkhBi86z9kb8XOcij9Inkc
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CroSiteClient = void 0;
const types_1 = require("../types");
const DOMAIN_CONFIG = {
    xtool: {
        local: {},
        dev: {
        // passport: 'https://xtool-passport-api-dev.xtool.com',
        // customthing: 'https://xtool-passport-api-dev.xtool.com',
        },
        test: {
            passport: 'https://passport-api-test.xtool.com',
            customthing: 'https://passport-api-test.customthings.com',
            designFind: 'https://passport-api-test.designfind.com',
            atomm: 'https://passport-api-test.atomm.com',
        },
        "pre-prod": {
            passport: 'https://passport-api-pre.xtool.com',
            customthing: 'https://passport-api-pre.customthings.com',
            designFind: 'https://passport-api-pre.designfind.com',
            atomm: 'https://passport-api-pre.atomm.com',
        },
        prod: {
            passport: 'https://passport-api.xtool.com',
            customthing: 'https://passport-api.customthings.com',
            designFind: 'https://passport-api.designfind.com',
            atomm: 'https://passport-api.atomm.com',
        },
    },
    makeblock: {
        local: {},
        dev: {},
        test: {},
        "pre-prod": {},
        prod: {},
    }
};
const path = `/v1/cookie/set-cookies`;
const path2 = `/v1/cookie/add-cookies`;
class CroSiteClient {
    constructor(config) {
        this._env = types_1.CLIENT_ENV.PROD;
        this._domainSpace = types_1.DOMAIN_SPACE.XTOOL;
        const { env, domainSpace } = config;
        if (env) {
            this.env = env;
        }
        if (domainSpace) {
            this.domainSpace = domainSpace;
        }
    }
    set env(value) {
        this._env = value;
    }
    get env() {
        return this._env;
    }
    set domainSpace(value) {
        this._domainSpace = value;
    }
    get domainSpace() {
        return this._domainSpace;
    }
    get domain() {
        return DOMAIN_CONFIG[this.domainSpace][this.env];
    }
    getSubHost(url) {
        return new URL(url).hostname.split('.').slice(1).join('.');
    }
    setCookie(url) {
        return new Promise((resolve) => {
            let img = new Image();
            img.onload = img.onerror = function () {
                img = null;
                resolve(true);
            };
            img.src = url;
        });
    }
    setCrosSiteCookie(utoken) {
        return __awaiter(this, void 0, void 0, function* () {
            const domain = this.domain;
            const requestPromises = [];
            for (const key in domain) {
                const url = domain[key];
                requestPromises.push(this.setCookie(`${url}${path}?utoken=${utoken}&domain=${this.getSubHost(url)}`));
            }
            return Promise.all(requestPromises);
        });
    }
    setThirdPartyCookie(lastTarget, token) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!token)
                return;
            let addCookieBlackLists = yield this.preFetchThirdPartyCookie();
            console.log('addCookieBlackLists', addCookieBlackLists);
            const domain = this.domain;
            const croSiteDomain = [];
            for (const key in domain) {
                const url = domain[key];
                // 挑出来当前域名下的地址不要
                if (!url.includes(`.${this.getSubHost(location.origin)}`) &&
                    !addCookieBlackLists.includes(`${url}${path2}`)) {
                    croSiteDomain.push(`${url}${path2}`);
                }
            }
            let url = lastTarget;
            for (let index = croSiteDomain.length - 1; index >= 0; index--) {
                url = `${croSiteDomain[index]}?utoken=${token}&domain=${this.getSubHost(croSiteDomain[index])}&target=${encodeURIComponent(url)}`;
            }
            window.location.href = url;
        });
    }
    //检测add-cookie的接口，挂了就去除掉
    preFetchThirdPartyCookie() {
        return __awaiter(this, void 0, void 0, function* () {
            const addCookieBlackLists = [];
            const domain = this.domain;
            let fetchs = [];
            for (const key in domain) {
                const url = domain[key];
                fetchs.push(`${url}${path2}`);
            }
            yield Promise.all(fetchs.map((url) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield fetch(url);
                    return true;
                }
                catch (error) {
                    addCookieBlackLists.push(url);
                    return false;
                }
            })));
            return addCookieBlackLists;
        });
    }
}
exports.CroSiteClient = CroSiteClient;
